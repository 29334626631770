<template>
  <div ID="MAIN__SITE">

    <div id="CONTENT__CENTER" class="center-card">
      <div id="FLEX__CARD" class="flex-card">
        <div id="THE__CARD" class="card">
          <FestinaCard />
          <ContentCard />
        </div>
      </div>
    </div>

    <FooterCard />

  </div>
</template>

<script>
import FestinaCard from './comps/FestinaCard.vue'
import ContentCard from './comps/ContentCard.vue'
import FooterCard from './comps/FooterCard.vue'

export default {
  name: 'HomePage',
  components: { FestinaCard, ContentCard, FooterCard },
  mounted() {

    function gtag() {
      window.dataLayer.push(arguments);
    }


    document.getElementById('TARIFS').addEventListener('click', function () {
      gtag('event', 'Click_Tarifs', {
        'event_category': 'Interaction',
        'event_label': 'TARIFS'
      });
    });


    document.getElementById('MAIL').addEventListener('click', function () {
      gtag('event', 'Click_Mail', {
        'event_category': 'Interaction',
        'event_label': 'MAIL'
      });
    });

    document.getElementById('INSTAGRAM').addEventListener('click', function () {
      gtag('event', 'Click_Instagram', {
        'event_category': 'Interaction',
        'event_label': 'INSTAGRAM'
      });
    });
  }
}
</script>
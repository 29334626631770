<template>

  <router-view v-slot="{ Component }">
    <transition :name="transitionName" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>

  <!-- <CookieBanner
  v-if="showBanner"
      @hideBanner="showBanner = false"
  />  -->

</template>
<script>
import HomePage from './components/HomePage.vue'
// import CookieBanner from './components/cookies/CookieBanner.vue'

export default {
  name: 'App',
  components: { HomePage },
  data() {
    return {
      transitionName: 'slideHome',
      transitioning: false,
      previousRoute: null,
      // showBanner: !(this.$posthog.has_opted_out_capturing()||this.$posthog.has_opted_in_capturing())
    }
  },
  created() {
    this.previousRoute = this.$route.path;
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    }
  },
  watch: {
    '$route'() {
      this.transitioning = true
    },
    currentRoute(newRoute) {
      const isNewRouteHome = newRoute === "/";
      const isPreviousRouteHome = this.previousRoute === "/";

      if (isPreviousRouteHome && isNewRouteHome) {
        this.transitionName = "slide--HOME_TO_PAGE";
      } else {
        this.transitionName = this.$route.meta.transition;
      }

      this.previousRoute = newRoute;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.transitioning = false
    next()
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style>
@import url("https://use.typekit.net/evu1xsd.css");
@import url("https://use.typekit.net/pip0aal.css");
/* CMS_2024 */
@import url("https://use.typekit.net/gah1fjn.css");
/* Atelier du Palais */

@import url('./styles/reset.css');
@import url('./styles/base.css');
@import url('./styles/grid.css');
@import url('./styles/styles.css');
@import url('./styles/animations.css');
@import url('./styles/transitions.css');
@import url('./styles/responsive.css');

#app {
  position: absolute;
  top: 0;
  left: 0;
}
</style>

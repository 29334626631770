import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createMemoryHistory } from 'vue-router'
// import { VueGtag } from 'vue-gtag'
// import posthogPlugin from "./components/cookies/posthog.js"; 
import axios from 'axios';

import HomePage from '/src/components/HomePage.vue'

const router = createRouter({
  history: createMemoryHistory(),
  routes: [
    {
      name: 'HomePage',
      path: '/',
      route: '/',
      component: HomePage,
      meta: {
        transition: 'slideHome',
        title: '',
        description: '',
        keywords: ''
      },
    }
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
});

router.beforeEach(async (to) => {
  try {
    const response = await axios.get('https://festinalente.studio/view/public/app/api/head.json');
    const jsonData = response.data;

    document.title = to.meta.title = jsonData.title;

    if (jsonData.description) {
      const metaDescription = document.createElement('meta');
      metaDescription.name = 'description';
      metaDescription.content = jsonData.description;
      document.head.appendChild(metaDescription);
    } else {
      const existingDescription = document.head.querySelector('meta[name="description"]');
      if (existingDescription) {
        existingDescription.remove();
      }
    }

    if (jsonData.keywords) {
      const metaKeywords = document.createElement('meta');
      metaKeywords.name = 'keywords';
      metaKeywords.content = jsonData.keywords;
      document.head.appendChild(metaKeywords);
    } else {
      const existingKeywords = document.head.querySelector('meta[name="keywords"]');
      if (existingKeywords) {
        existingKeywords.remove();
      }
    }
  } catch (error) {
    console.error('Erreur lors du chargement du fichier JSON', error);
  }
});

export default router;


const VueApp = createApp(App);

VueApp.use(router);
// VueApp.use(VueGtag, { config : { id : "G-SZ7LPQP5M7" } }) 
// VueApp.use(posthogPlugin);

VueApp.mount('#app');

<template>
    <div id="FESTINA" class="card">
        <div class="flex">
            <span style="text-transform: uppercase">{{ title }}</span>
            <span class="sub">{{ sub_title }}</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            title: '',
            sub_title: ''
        };
    },
    created() {
        axios.get('https://festinalente.studio/view/public/app/api/contents.json')
            .then(response => {
                this.title = response.data.title;
                this.sub_title = response.data.sub_title;
            })
            .catch(error => {
                console.error('Erreur lors du chargement du fichier JSON', error);
            });
    }
}
</script>
<template>
    <div class="hide" id="CONTENT__CARD">

        <div class="container two">
            <span class="titre infoA">{{ info_A }}</span>
            <span class="titre infoB">{{ info_B }}</span>
            <span class="titre infoC">{{ info_C }}</span>
            <span class="titre infoD ville">{{ info_ville }}</span>

        </div>

    </div>

    <a id="TARIFS" :href="'http://festinalente.studio/view/public/app/documents/' + link_tarifs" target="_blank" class="info" style="color:#a78c8c;">_{{
                tarifs }}</a>

    <div id="CLICK_ANIMATION" class="clic" style="display: none;"></div>

</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            info_A: '',
            info_B: '',
            info_C: '',
            info_ville: '',
            tarifs: '',
            link_tarifs: '',
            timing: ''
        };
    },
    created() {
        axios.get('https://festinalente.studio/view/public/app/api/contents.json')
            .then(response => {
                this.info_A = response.data.info_A;
                this.info_B = response.data.info_B;
                this.info_C = response.data.info_C;
                this.info_ville = response.data.info_ville;
                this.tarifs = response.data.tarifs;
                this.link_tarifs = response.data.link_tarifs;
                this.timing = response.data.timing;
            })
            .catch(error => {
                console.error('Erreur lors du chargement du fichier JSON', error);
            });
    },
    methods: {
        TIME_FADE() {
            const FESTINA = document.getElementById('FESTINA')
            const CARD = document.getElementById('CONTENT__CARD');
            const INFO_A = document.querySelector('.infoA');
            const INFO_B = document.querySelector('.infoB');
            const INFO_C = document.querySelector('.infoC');
            const INFO_D = document.querySelector('.infoD');

            FESTINA.classList.add('pointer-none')

            setTimeout(() => {
                INFO_D.classList.remove('opacity')
            }, 50);

            setTimeout(() => {
                INFO_C.classList.remove('opacity')
            }, 500);

            setTimeout(() => {
                INFO_B.classList.remove('opacity')
            }, 800);

            setTimeout(() => {
                INFO_A.classList.remove('opacity')
            }, 1200);

            setTimeout(() => {
                CARD.classList.remove('opacity')
            }, 1550);

            setTimeout(() => {

                CARD.classList.remove('position')
            }, 1600);
        },
        startInactivityTimer() {
            const timing = this.timing
            this.timer = setTimeout(() => {
                this.TIME_FADE();
            }, timing);
        },
        resetInactivityTimer() {
            clearTimeout(this.timer);
            this.isMouseInsideElement;
            this.startInactivityTimer();

            const FESTINA = document.getElementById('FESTINA')

            setTimeout(() => {
                FESTINA.classList.remove('pointer-none')
            }, 50);

        },

        isMouseInsideElement(e) {
            const festinaElement = document.getElementById('FESTINA');
            const mouseX = e.clientX;
            const mouseY = e.clientY;
            const rect = festinaElement.getBoundingClientRect();

            return (
                mouseX >= rect.left &&
                mouseX <= rect.right &&
                mouseY >= rect.top &&
                mouseY <= rect.bottom
            );
        },
    },
    mounted() {
        const FESTINA = document.getElementById('FESTINA')
        const CARD = document.getElementById('CONTENT__CARD')

        const INFO_A = document.querySelector('.infoA')
        const INFO_B = document.querySelector('.infoB')
        const INFO_C = document.querySelector('.infoC')
        const INFO_D = document.querySelector('.infoD')

        FESTINA.addEventListener('mouseover', () => {
            this.startInactivityTimer();

            setTimeout(() => {
                CARD.classList.add('position')
            }, 50);

            setTimeout(() => {
                CARD.classList.add('opacity')
            }, 200);

            setTimeout(() => {
                INFO_A.classList.add('opacity')
            }, 600);

            setTimeout(() => {
                INFO_B.classList.add('opacity')
            }, 1100);

            setTimeout(() => {
                INFO_C.classList.add('opacity')
            }, 1600);

            setTimeout(() => {
                INFO_D.classList.add('opacity')
            }, 2100);

        })


        window.addEventListener('mousemove', this.resetInactivityTimer);
        window.addEventListener('keypress', this.resetInactivityTimer);

    },
    unmounted() {
        window.removeEventListener('mousemove', this.resetInactivityTimer);
        window.removeEventListener('keypress', this.resetInactivityTimer);
    }
}
</script>
<template>
    <div id="FOOTER__CARD">

        <div class="container">
            <div class="info">
                <a :href="'http://festinalente.studio/view/public/app/documents/' + link_tarifs" target="_blank" class="mail mobile"
                    style="color:#a78c8c; font-size:140%">_{{ tarifs
                    }}</a>

                <a id="INSTAGRAM" class="mail" target="_blank"
                    :href="'https://instagram.com/' + link_instagram">
                    <i class='bx bxl-instagram-alt'></i> {{ instagram }}</a>
                <a id="MAIL" class="mail" :href="'mailto:' + mail">{{ mail }}</a>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import 'boxicons';

export default {
    data() {
        return {
            tarifs: '',
            link_tarifs: '',
            instagram: '',
            link_instagram: '',
            mail: ''
        };
    },



    created() {
        axios.get('https://festinalente.studio/view/public/app/api/contents.json')
            .then(response => {
                this.tarifs = response.data.tarifs;
                this.link_tarifs = response.data.link_tarifs;
                this.instagram = response.data.instagram;
                this.link_instagram = response.data.link_instagram;
                this.mail = response.data.mail;
            })
            .catch(error => {
                console.error('Erreur lors du chargement du fichier JSON', error);
            });
    }
}
</script>